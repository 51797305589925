<template>
	<div>
		<div class="card">

			<v-server-table
				ref="companiesTable"
				:columns="columns"
				:options="options"
				@loading="tableLoading = true"
				@loaded="tableLoading = false"
				>
				<span slot="id" slot-scope="props">
					<span>{{ props.index }}</span>
				</span>

				<div slot="User Type" slot-scope="props">

					<div>{{ props.row.user_type }}</div>
				</div>

				<div slot="User" slot-scope="props">

					<div>
						<span>{{ props.row[props.row.user_type].lname + ' '+props.row[props.row.user_type].fname }}</span>
					</div>
				</div>

				<div slot="Applied At" slot-scope="props">
					<div>
						<span>{{
							props.row.created_at ? props.row.created_at : 'Never' | dateformat
						}}</span>
					</div>
				</div>

				<div slot="Status" slot-scope="props">
					<div
						class="badge"
						:class="{
							'badge-success': !props.row.is_consumed == 1,
							'badge-warning': props.row.is_consumed == 0,
						}"
						>
						{{ !props.row.is_consumed == 1 ? 'Completed' : 'Pending' }}
					</div>
				</div>
			</v-server-table>
		</div>
	</div>
</template>

<script>
import promoCode from '@/api/promo_codes'

import moment from 'moment'

export default {
  filters: {
    dateformat (v) {
      if (v == 'Never') return v
      return moment(v).format('YYYY-MM-DD HH:mm')
    },
    date (value) {
      if (!value) return ''
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  props: {
    promoCodeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    const vm = this
    return {
      tableLoading: false,
      companies: null,
      columns: [
        'id',
        'User Type',
        'User',
        'Applied At',
        'Status'
      ],
      options: {
        filterable: false,
        requestFunction: (data) => {
          return vm.fetchPromoCodeUsages(data).catch(function (e) {
            
          })
        }
      }
    }
  },
  methods: {
    async fetchPromoCodeUsages (params) {
      const result = await promoCode.listUsages(this.promoCodeId, { params })
      const newResult = {
        ...result.data,
        data: result.data.models,
        count: result.data.meta.total
      }
      return {
        data: newResult
      }
    }
  }
}
</script>
