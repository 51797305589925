<template>
	<div class="p-3">
		<div class="px-3">
			<nav aria-label="breadcrumb">
				<ol class="breadcrumb">
					<li class="breadcrumb-item">
						<router-link to="/">Home</router-link>
					</li>
					<li class="breadcrumb-item">
						<router-link :to="{ name: 'ListPromoCode' }"
							>Promo Codes</router-link
							>
					</li>
					<li class="breadcrumb-item active" aria-current="page">
						Promo Code - {{ promoCode ? promoCode.name : '' }}
					</li>
				</ol>
			</nav>
		</div>
		<div class="d-flex" v-if="promoCode">
			<div class="col-4">
				<div class="card">
					<div class="card-body">
						<div class="form-group">
							<label for="">Name</label>
							<h2>{{ promoCode.name }}</h2>
						</div>

						<div class="form-group">
							<label for="">Description</label>
							<h2>{{ promoCode.description }}</h2>
						</div>

						<div class="form-group">
							<label for="">Promo Code</label>
							<h2>{{ promoCode.promo_code }}</h2>
						</div>

						<div class="form-group">
							<label for="">Expires At</label>
							<h2>
								{{
									promoCode.expires_at
										? promoCode.expires_at
										: 'Never' | dateformat
								}}
							</h2>
							<div v-if="promoCode.has_promotion_expired">
								<span class="badge badge-danger">expired</span>
							</div>
						</div>

						<div class="form-group">
							<label for="">MAXIMUM NUMBER OF USES</label>
							<h2>{{ promoCode.max_number_of_uses }}</h2>
						</div>

						<div class="form-group">
							<label for="">NUMBER OF USAGES</label>
							<h2>{{ promoCode.number_of_usages }}</h2>
						</div>

						<div class="form-group mb-4">
							<h3 class="text-info">Customer Benefits</h3>
							<div v-if="promoCode.should_customer_benefit == 0">
								<span class="badge badge-danger">NO</span>
							</div>
							<div v-if="promoCode.customer_benefit">
								<div class="form-group">
									<label for="">Benefit</label>
									<h2>
										{{ promoCode.customer_benefit.reward_kind | titleCase }}
									</h2>
								</div>
								<div class="form-group">
									<label for="">Amount</label>
									<h2>{{ promoCode.customer_benefit.reward_amount }}</h2>
								</div>

								<div class="form-group">
									<label for="">Customer Constrain</label>
									<h3>
										{{
											promoCode.customer_benefit.customer_constraint == null
												? 'none'
												: promoCode.customer_benefit.customer_constraint
												| titleCase
										}}
									</h3>
								</div>
							</div>
						</div>

						<div class="form-group">
							<h3 class="text-info">Owner Benefits</h3>
							<div v-if="promoCode.should_owner_benefit == 0">
								<span class="badge badge-danger">NO</span>
							</div>

							<div v-if="promoCode.owner_benefit">
								<div class="form-group">
									<label for="">Benefit</label>
									<h3>{{ promoCode.owner_benefit.reward_kind | titleCase }}</h3>
								</div>
								<div class="form-group">
									<label for="">Amount</label>
									<h3>{{ promoCode.owner_benefit.reward_amount }}</h3>
								</div>

								<div class="form-group">
									<label for="">Customer Constraint</label>
									<h3>
										{{
											promoCode.owner_benefit.customer_constraint == null
												? 'none'
												: promoCode.owner_benefit.customer_constraint
												| titleCase
										}}
									</h3>
								</div>
							</div>
						</div>

						<div class="form-group" v-if="!promoCode.has_promotion_expired && userHasPermission('promotions004')"">
							<button class="btn btn-danger" @click="endPromoCode()">{{deleting ? 'Processing...': 'End Promo Code'}}</button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-8">
				<PromoCodeUsages
					:promoCodeId="promoCodeId"
					:data="promoCodeUserData"
					/>
			</div>
		</div>
	</div>
</template>

<script setup>
import { userHasPermission } from '@/composables/core/permissions'
</script>

<script>
import PromoCodeUsages from '../PromoCodeUsages.vue'
import promoCode from '@/api/promo_codes'

import moment from 'moment'
import _ from 'lodash'

export default {
  filters: {
    titleCase (v) {
      return _.startCase(v)
    },
    dateformat (v) {
      if (v == 'Never') return v
      return moment(v).format('YYYY-MM-DD HH:mm')
    },
    date (value) {
      if (!value) return ''
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return Intl.DateTimeFormat('en-US', options).format(value)
    }
  },
  components: {
    PromoCodeUsages
  },
  props: {
    promoCodeId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      promoCode: null,
      isLoading: false,
      promoCodeUserData: [],
      deleting: false
    }
  },
  mounted () {
    this.loadPromoCode()
  },
  methods: {
    async loadPromoCode () {
      this.isLoading = true
      try {
        const { data } = await promoCode.show(this.promoCodeId)
        this.promoCodeUserData = data.users.length ? data.users : []
        this.promoCode = data
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.showSwalErrorAlert(e, 'Failed to load promo details')
      }
    },
    endPromoCode () {
      this.deleting = true
      promoCode.endPromotionById(this.promoCodeId)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Promo ended successfully!',
            text: '',
            showCloseButton: true
          }).then(() => {
            this.$router.push({
              name: 'ListPromoCode'
            })
          })
        })
        .catch((e) => {
          this.showSwalErrorAlert(e, 'Failed to delete promo')
        })
        .finally(() => {
          this.deleting = false
        })
    },
    showSwalErrorAlert (e, message) {
      this.$swal({
        icon: 'error',
        title: message,
        text:
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : e.toString(),
        showCloseButton: true
      })
    }
  }
}
</script>
